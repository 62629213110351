import { useColorMode } from "@chakra-ui/core";
import { Icon, useColorModeValue } from "@chakra-ui/react";
import { IconColors } from "../iconColors";

export const DaoIcon = ({
  customColor,
  customSize,
}: {
  customColor?: string;
  customSize?: number;
}) => {
  const color = useColorModeValue(IconColors.LIGHT, IconColors.DARK);

  return (
    <svg
      width={customSize ?? "14"}
      height={customSize ?? "14"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.468509 12.4021C0.138283 12.6956 0.108538 13.2013 0.402073 13.5315C0.695607 13.8617 1.20126 13.8915 1.53149 13.5979L0.468509 12.4021ZM6.03149 9.59793L6.62942 9.06644L5.56644 7.87058L4.96851 8.40207L6.03149 9.59793ZM5.84188 7.90117L6.26588 7.22277L5.84188 7.90117ZM6.11188 8.14917L6.75188 7.66917L6.11188 8.14917ZM8.69569 5.62231L8.27169 6.30071L8.69569 5.62231ZM6.66624 2.56598L5.98784 2.98998L6.66624 2.56598ZM11.5039 7.37747L11.0799 8.05587L11.5039 7.37747ZM9.81238 11.0716L10.4144 11.5984L9.81238 11.0716ZM1.53149 13.5979L6.03149 9.59793L4.96851 8.40207L0.468509 12.4021L1.53149 13.5979ZM10.4144 11.5984L12.3286 9.41077L11.1245 8.35717L9.21031 10.5448L10.4144 11.5984ZM4.54545 1.82318L2.32318 4.04545L3.45455 5.17682L5.67682 2.95455L4.54545 1.82318ZM11.9279 6.69907L9.11969 4.94391L8.27169 6.30071L11.0799 8.05587L11.9279 6.69907ZM9.05609 4.88031L7.34463 2.14198L5.98784 2.98998L7.69929 5.72831L9.05609 4.88031ZM2.64198 6.84463L5.41788 8.57957L6.26588 7.22277L3.48998 5.48784L2.64198 6.84463ZM5.47188 8.62917L7.6198 11.4931L8.8998 10.5331L6.75188 7.66917L5.47188 8.62917ZM5.41788 8.57957C5.43878 8.59264 5.45709 8.60945 5.47188 8.62917L6.75188 7.66917C6.61877 7.49169 6.454 7.34035 6.26588 7.22277L5.41788 8.57957ZM9.11969 4.94391C9.09394 4.92781 9.07219 4.90606 9.05609 4.88031L7.69929 5.72831C7.84416 5.96009 8.03991 6.15584 8.27169 6.30071L9.11969 4.94391ZM5.67682 2.95455C5.76776 2.86361 5.91967 2.88091 5.98784 2.98998L7.34463 2.14198C6.73113 1.16037 5.36396 1.00467 4.54545 1.82318L5.67682 2.95455ZM12.3286 9.41077C13.0587 8.57638 12.8681 7.28668 11.9279 6.69907L11.0799 8.05587C11.1844 8.12116 11.2056 8.26446 11.1245 8.35717L12.3286 9.41077ZM9.21031 10.5448C9.12661 10.6404 8.97607 10.6348 8.8998 10.5331L7.6198 11.4931C8.30621 12.4083 9.66109 12.4593 10.4144 11.5984L9.21031 10.5448ZM2.32318 4.04545C1.50467 4.86396 1.66038 6.23113 2.64198 6.84463L3.48998 5.48784C3.38091 5.41967 3.36361 5.26776 3.45455 5.17682L2.32318 4.04545Z"
        fill={customColor ?? color}
      />
      <path
        d="M7 16L15.5 16"
        stroke={customColor ?? color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M9 5.5L10 4.5"
        stroke={customColor ?? color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};
