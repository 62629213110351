import { useColorMode } from "@chakra-ui/core";
import { Icon, useColorModeValue } from "@chakra-ui/react";

export const CasperIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2749_30227)">
        <circle cx="9.5" cy="9.5" r="9.5" fill="#ECEFF0" />
        <path
          d="M6.21327 12.3581V6.66869C6.21327 6.53325 6.26391 6.39938 6.36599 6.3104C6.4475 6.23874 6.54403 6.20252 6.6564 6.20252H13.8557V4H13.7536C13.0746 4 12.5239 4.54807 12.5239 5.22371V5.24497C12.5239 5.57885 12.2651 5.87179 11.9296 5.88045C11.5854 5.88911 11.3029 5.61429 11.3029 5.27332V5.21505C11.3029 4.54413 10.7561 4.00079 10.0827 4.00079H6.256C5.82395 4.00079 5.437 4.09764 5.09516 4.29057C4.75332 4.48429 4.48507 4.75123 4.2912 5.09063C4.09733 5.43081 4 5.81588 4 6.24504V12.755C4 13.1849 4.09733 13.57 4.2912 13.9102C4.48586 14.2503 4.75332 14.5173 5.09516 14.7102C5.437 14.9039 5.82395 15 6.256 15H13.8541V12.7975H6.68093C6.54483 12.7975 6.4103 12.7471 6.32089 12.6455C6.24888 12.5644 6.21248 12.4683 6.21248 12.3565L6.21327 12.3581Z"
          fill="#FF2D2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2749_30227">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
