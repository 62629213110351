import { useColorMode } from "@chakra-ui/core";
import { Icon, useColorModeValue } from "@chakra-ui/react";
import { IconColors } from "../iconColors";

export const GuildIcon = ({
  customColor,
  customSize,
}: {
  customColor?: string;
  customSize?: number;
}) => {
  const color = useColorModeValue(IconColors.LIGHT, IconColors.DARK);

  return (
    <svg
      width={customSize ?? "20"}
      height={customSize ?? "20"}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3376_13653)">
        <path
          d="M4.75 7.96441V16.0312C4.75 16.1887 4.81256 16.3397 4.92391 16.4511C5.03526 16.5624 5.18628 16.625 5.34375 16.625H13.6562C13.8137 16.625 13.9647 16.5624 14.0761 16.4511C14.1874 16.3397 14.25 16.1887 14.25 16.0312V7.96441C14.2501 7.80715 14.3125 7.65634 14.4237 7.54508L15.2638 6.70492C15.375 6.59366 15.4374 6.44285 15.4375 6.28559V2.96875C15.4375 2.81128 15.3749 2.66026 15.2636 2.54891C15.1522 2.43756 15.0012 2.375 14.8438 2.375H13.6562C13.4988 2.375 13.3478 2.43756 13.2364 2.54891C13.1251 2.66026 13.0625 2.81128 13.0625 2.96875V4.15625C13.0625 4.31372 12.9999 4.46474 12.8886 4.57609C12.7772 4.68744 12.6262 4.75 12.4688 4.75H11.2812C11.1238 4.75 10.9728 4.68744 10.8614 4.57609C10.7501 4.46474 10.6875 4.31372 10.6875 4.15625V2.96875C10.6875 2.81128 10.6249 2.66026 10.5136 2.54891C10.4022 2.43756 10.2512 2.375 10.0938 2.375H8.90625C8.74878 2.375 8.59776 2.43756 8.48641 2.54891C8.37506 2.66026 8.3125 2.81128 8.3125 2.96875V4.15625C8.3125 4.31372 8.24994 4.46474 8.13859 4.57609C8.02724 4.68744 7.87622 4.75 7.71875 4.75H6.53125C6.37378 4.75 6.22276 4.68744 6.11141 4.57609C6.00006 4.46474 5.9375 4.31372 5.9375 4.15625V2.96875C5.9375 2.81128 5.87494 2.66026 5.76359 2.54891C5.65224 2.43756 5.50122 2.375 5.34375 2.375H4.15625C3.99878 2.375 3.84776 2.43756 3.73641 2.54891C3.62506 2.66026 3.5625 2.81128 3.5625 2.96875V6.28559C3.56257 6.44285 3.62503 6.59366 3.73617 6.70492L4.57633 7.54508C4.68747 7.65634 4.74993 7.80715 4.75 7.96441Z"
          stroke={customColor ?? color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.71875 16.625V11.2812C7.71875 10.8088 7.90642 10.3558 8.24047 10.0217C8.57451 9.68767 9.02758 9.5 9.5 9.5C9.97242 9.5 10.4255 9.68767 10.7595 10.0217C11.0936 10.3558 11.2812 10.8088 11.2812 11.2812V16.625"
          stroke={customColor ?? color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3376_13653">
          <rect width="19" height="19" fill={customColor ?? color} />
        </clipPath>
      </defs>
    </svg>
  );
};
