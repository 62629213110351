import { useColorMode } from "@chakra-ui/core";
import { Icon, useColorModeValue } from "@chakra-ui/react";

export const BNBIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2088_2855)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C12.4186 0 16 3.58141 16 8C16 12.4186 12.4186 16 8 16C3.58141 16 0 12.4186 0 8C0 3.58141 3.58141 0 8 0Z"
          fill="#F0B90B"
        />
        <path
          d="M4.39689 7.99996L4.40266 10.1153L6.2001 11.173V12.4115L3.35074 10.7403V7.38137L4.39689 7.99996ZM4.39689 5.88458V7.11727L3.3501 6.49804V5.26535L4.39689 4.64612L5.44882 5.26535L4.39689 5.88458ZM6.95074 5.26535L7.99753 4.64612L9.04946 5.26535L7.99753 5.88458L6.95074 5.26535Z"
          fill="white"
        />
        <path
          d="M5.15332 9.67689V8.43843L6.20012 9.05766V10.2903L5.15332 9.67689ZM6.95076 11.6166L7.99755 12.2359L9.04947 11.6166V12.8493L7.99755 13.4686L6.95076 12.8493V11.6166ZM10.5508 5.26535L11.5976 4.64612L12.6495 5.26535V6.49804L11.5976 7.11727V5.88458L10.5508 5.26535ZM11.5976 10.1153L11.6033 7.99996L12.6501 7.38073V10.7397L9.80076 12.4109V11.1724L11.5976 10.1153Z"
          fill="white"
        />
        <path
          d="M10.8466 9.67682L9.7998 10.2903V9.05759L10.8466 8.43835V9.67682Z"
          fill="white"
        />
        <path
          d="M10.8469 6.32304L10.8527 7.5615L9.05012 8.61919V10.7397L8.00332 11.3532L6.95652 10.7397V8.61919L5.15396 7.5615V6.32304L6.20524 5.70381L7.99691 6.76663L9.79947 5.70381L10.8514 6.32304H10.8469ZM5.15332 4.20829L7.99755 2.53137L10.8469 4.20829L9.80012 4.82753L7.99755 3.76471L6.20012 4.82753L5.15332 4.20829Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2088_2855">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
