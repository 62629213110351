import { useColorMode } from "@chakra-ui/core";
import { Icon, useColorModeValue } from "@chakra-ui/react";

export const PolygonIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#8247E5" />
      <g clipPath="url(#clip0_2088_2906)">
        <path
          d="M10.3501 6.53712C10.1822 6.44116 9.9663 6.44116 9.77438 6.53712L8.43095 7.32879L7.51933 7.83258L6.19988 8.62424C6.03196 8.7202 5.81605 8.7202 5.62413 8.62424L4.59256 8.00051C4.42463 7.90455 4.30468 7.71263 4.30468 7.49672V6.29722C4.30468 6.1053 4.40064 5.91338 4.59256 5.79343L5.62413 5.19369C5.79206 5.09773 6.00797 5.09773 6.19988 5.19369L7.23145 5.81742C7.39938 5.91338 7.51933 6.1053 7.51933 6.32121V7.11288L8.43095 6.5851V5.76945C8.43095 5.57753 8.33499 5.38561 8.14307 5.26566L6.22387 4.13813C6.05595 4.04217 5.84004 4.04217 5.64812 4.13813L3.68095 5.28965C3.48903 5.38561 3.39307 5.57753 3.39307 5.76945V8.0245C3.39307 8.21641 3.48903 8.40833 3.68095 8.52828L5.62413 9.65581C5.79206 9.75177 6.00797 9.75177 6.19988 9.65581L7.51933 8.88813L8.43095 8.36035L9.75039 7.59268C9.91832 7.49672 10.1342 7.49672 10.3261 7.59268L11.3577 8.19243C11.5256 8.28838 11.6456 8.4803 11.6456 8.69621V9.89571C11.6456 10.0876 11.5496 10.2795 11.3577 10.3995L10.3501 10.9992C10.1822 11.0952 9.9663 11.0952 9.77438 10.9992L8.74281 10.3995C8.57488 10.3035 8.45494 10.1116 8.45494 9.89571V9.12803L7.54332 9.65581V10.4475C7.54332 10.6394 7.63928 10.8313 7.8312 10.9513L9.77438 12.0788C9.94231 12.1747 10.1582 12.1747 10.3501 12.0788L12.2933 10.9513C12.4612 10.8553 12.5812 10.6634 12.5812 10.4475V8.16844C12.5812 7.97652 12.4852 7.7846 12.2933 7.66465L10.3501 6.53712Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2088_2906">
          <rect
            width="9.21212"
            height="8.03662"
            fill="white"
            transform="translate(3.39307 4.09021)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
