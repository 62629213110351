import { useColorMode } from "@chakra-ui/core";
import { Icon, useColorModeValue } from "@chakra-ui/react";
import { IconColors } from "../iconColors";

export const FiatIcon = ({
  customColor,
  customSize,
}: {
  customColor?: string;
  customSize?: number;
}) => {
  const color = useColorModeValue(IconColors.LIGHT, IconColors.DARK);

  return (
    <svg
      width={customSize ?? "18"}
      height={customSize ?? "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.39635 16.0771C4.17512 15.5567 2.25298 13.9591 1.41864 11.654C0.10649 8.02871 1.98163 4.02617 5.60688 2.71406L6.42697 2.41723"
        stroke={customColor ?? color}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6038 2.48066C12.825 3.00111 14.7471 4.59868 15.5815 6.90385C16.8936 10.5291 15.0185 14.5316 11.3932 15.8438"
        stroke={customColor ?? color}
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.13916 1.00004L7.2088 1.99044L6.2392 4.10449"
        stroke={customColor ?? color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M12.1338 17.1729L10.0642 16.1824L11.0338 14.0684"
        stroke={customColor ?? color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M8.03265 13.9093V4.81836H8.61503V13.9093H8.03265ZM9.57739 7.81907C9.54898 7.53261 9.42706 7.31007 9.21163 7.15146C8.99619 6.99284 8.70381 6.91353 8.3345 6.91353C8.08355 6.91353 7.87166 6.94904 7.69884 7.02006C7.52602 7.08872 7.39344 7.1846 7.30111 7.30771C7.21115 7.43081 7.16617 7.57049 7.16617 7.72674C7.16144 7.85695 7.18866 7.97059 7.24785 8.06765C7.3094 8.16471 7.39344 8.24876 7.49998 8.31978C7.60651 8.38844 7.72962 8.4488 7.8693 8.50089C8.00897 8.5506 8.15812 8.59322 8.31674 8.62873L8.97015 8.78498C9.28738 8.856 9.57858 8.9507 9.84373 9.06907C10.1089 9.18744 10.3385 9.33304 10.5326 9.50586C10.7268 9.67868 10.8771 9.88228 10.9836 10.1167C11.0925 10.351 11.1482 10.6197 11.1505 10.9228C11.1482 11.3678 11.0345 11.7537 10.8096 12.0804C10.5871 12.4048 10.2651 12.6569 9.84373 12.8368C9.42469 13.0144 8.91925 13.1032 8.32739 13.1032C7.74027 13.1032 7.22891 13.0132 6.7933 12.8333C6.36006 12.6534 6.02152 12.387 5.77768 12.0343C5.5362 11.6792 5.40954 11.24 5.39771 10.7168H6.88563C6.9022 10.9606 6.97204 11.1642 7.09515 11.3276C7.22062 11.4886 7.38753 11.6105 7.59586 11.6934C7.80656 11.7739 8.04449 11.8141 8.30964 11.8141C8.57005 11.8141 8.79614 11.7762 8.9879 11.7005C9.18203 11.6247 9.33236 11.5194 9.4389 11.3844C9.54543 11.2495 9.5987 11.0944 9.5987 10.9192C9.5987 10.7559 9.55017 10.6185 9.4531 10.5073C9.35841 10.396 9.21873 10.3013 9.03407 10.2232C8.85178 10.1451 8.62806 10.074 8.3629 10.0101L7.571 9.81126C6.95784 9.66211 6.4737 9.42892 6.11859 9.11168C5.76347 8.79445 5.5871 8.36713 5.58947 7.82972C5.5871 7.38938 5.70429 7.00468 5.94103 6.6756C6.18014 6.34653 6.50803 6.08967 6.92469 5.90501C7.34136 5.72035 7.81485 5.62802 8.34515 5.62802C8.88492 5.62802 9.35604 5.72035 9.7585 5.90501C10.1633 6.08967 10.4782 6.34653 10.7031 6.6756C10.928 7.00468 11.044 7.38583 11.0511 7.81907H9.57739Z"
        fill={customColor ?? color}
      />
    </svg>
  );
};
